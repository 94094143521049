import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a967db4c")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex gap-2 justify-content-end" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
_popScopeId()

import { computed } from "vue";
import { useCpmiListStore } from "@/store/pinia/gws-agent/cpmi/useCpmiListStore";
import { ServiceListItem as ListItem } from "@/core/types/gws-blk/ServiceListItem";
import { useCpmiFormStore } from "@/store/pinia/gws-agent/cpmi/useCpmiFormStore";
import { simpleFormatDate } from "@/core/helpers/date-format";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";
import { useGlobalLookupStore } from "@/store/pinia/application/useGlobalLookupStore";
//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: ["approve", "reject", "view-detail-modal"],
  setup(__props, { emit: emits }) {

const props = __props

const listStore = useCpmiListStore();
const formStore = useCpmiFormStore();
const authStore = useAuthenticationStore();
const lookupStore = useGlobalLookupStore();



const orgType = computed(() => {
  return authStore.user.org_type || "";
});
const handleViewDetail = (item: ListItem) => {
  emits("view-detail", item.id);
};
const handleApprove = (item: ListItem) => {
  lookupStore.getLookupServiceGroups(item.vocational_id, item.blk_id);
  emits("approve", item);
};
const handleReject = (item: ListItem) => {
  emits("reject", item);
};
const handleShowDetailModal = (item: ListItem) => {
  formStore.stateUpdateId = item.id;
  emits("view-detail-modal", item);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            label: "No.",
            width: "50",
            type: "index",
            align: "center"
          }),
          _createVNode(_component_el_table_column, {
            prop: "first_name",
            label: "Tanggal Pengajuan",
            "min-width": "100",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(simpleFormatDate)(scope.row.service_date)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "first_name",
            label: "Nama Pencaker",
            "min-width": "100",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.cpmi.first_name) + " " + _toDisplayString(scope.row.cpmi.middle_name) + " " + _toDisplayString(scope.row.cpmi.first_name) + "   ", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "book_number",
            label: "Kode Reservasi",
            "min-width": "100",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.book_number), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "",
            fixed: "right",
            align: "right",
            "min-width": "150"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                (!scope.row.approved_at && !scope.row.rejected_at)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-action approve",
                      onClick: ($event: any) => (handleApprove(scope.row))
                    }, " Setujui ", 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (!scope.row.rejected_at && !scope.row.approved_at)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn-action reject",
                      onClick: ($event: any) => (handleReject(scope.row))
                    }, " Tolak ", 8, _hoisted_4))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  class: "btn-action info",
                  onClick: ($event: any) => (handleShowDetailModal(scope.row))
                }, " Detail ", 8, _hoisted_5)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})