import { defineStore } from "pinia";
import { LspApprovalFormData as FormData } from "@/core/types/gws-lsp/services/LspApprovalFormData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";

const authStore = useAuthenticationStore();
export const useLspCertFormStore = defineStore({
  id: "lspCertFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateUpdateId: "",
      stateUpdateLsp: "",
      stateCertificateDetail: {
        name: "",
        course: "",
      },
      statePayloadCpmiId: "",
      stateCertificateFile: "",
      stateUploadType: "",
    };
  },
  getters: {
    loading: (state) => {
      return state.stateIsLoading;
    },
    formData: (state) => {
      return state.stateFormData;
    },
    error: (state) => {
      return state.stateError;
    },
    errors: (state) => {
      return state.stateErrors;
    },
  },
  actions: {
    async approveService(isPageRefresh = true) {
      this.stateIsLoading = true;
      try {
        const payload = {
          reject_reason: "",
          is_rejected: false,
          examination_date: this.stateFormData.examination_date,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${this.stateUpdateId}/services-lsp/${this.stateUpdateLsp}/approval`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },

    async rejectService(isPageRefresh = true) {
      this.stateIsLoading = true;
      try {
        const payload = {
          reject_reason: this.stateFormData.reject_reason,
          is_rejected: true,
          examination_date: "",
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${this.stateUpdateId}/services-lsp/${this.stateUpdateLsp}/approval`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async uploadCertificate(id: string) {
      this.stateIsLoading = true;
      try {
        const payload = {
          type: this.stateUploadType,
          certificate_file: this.stateCertificateFile,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${this.statePayloadCpmiId}/services-lsp/${id}/upload-certificate`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateError = true;
        console.log(error);
        this.stateErrors.push(error.toString());
        // const metaResp = error.response.data.meta as Meta;
        // metaResp.errors[0].forEach((err) => {
        //   this.stateErrors.push(err.message);
        // });
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = {} as FormData;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
      this.stateError = this.stateErrors.length > 0;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
  },
});
